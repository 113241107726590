import TransportWebClient from './transportWebClient';
import transportConfig from '../../config/tarnsport';

if (SERVER_HOST)
    transportConfig.host = SERVER_HOST;

const kenoTransport = new TransportWebClient(transportConfig);

if (SEC_SERVER_HOST)
    transportConfig.host = SEC_SERVER_HOST;

const penaltyTransport = new TransportWebClient(transportConfig);

export { kenoTransport, penaltyTransport }