import { Scene } from 'core';
import gameSceneView from './view';
import game from './config/game';
import { kenoTransport, penaltyTransport } from '../../transport/transport';

import debug from 'core/debug';

const formatDate = str => {
    return str.toString().padStart(2, '0');
}

export default class GameScene extends Scene {
    constructor(resources) {
        super(gameSceneView, resources);

        this.resources = resources;

        this.createLinksForViewObjects();

        // debug(this.view);

        this.scatters = {
            durdonai_garonbaho: 12,
            gambusaki_hugihol: 9,
            haradmandi_hushkhol: 12,
            hocha_nastriddin: 12,
            kissaho: 12,
            kissahoj_pomir: 12,
            mevaho: 7,
            misa: 12,
            rohi_abreshim: 9,
            turbo_keno: null,
            lotosport: null,
        };

        this.gameTypes = {
            lottery: ['x_keno', 'z_keno', 'jx_keno', 'keno_22'],
            slot: [...Object.keys(this.scatters)],
            roulette: ['penalty']
        }
    }

    getConfig() {
        return game;
    }

    getOptions() {
        return {
            actions: {
                showGameIcon: this.showGameIcon.bind(this),
                hideGameIcon: this.hideGameIcon.bind(this),
                listenForUpdates: this.listenForUpdates.bind(this),
                removeUpdatesListener: this.removeUpdatesListener.bind(this),
                listenForGameUpdates: this.listenForGameUpdates.bind(this),
                removeGameUpdatesListener: this.removeGameUpdatesListener.bind(this),
                requestLastDraws: this.requestLastDraws.bind(this),
            },
            services: {
                init: this.init.bind(this)
            },
        };
    }

    async init() {
        const gmtOffset = new Date().getTimezoneOffset();

        this.gmt = `${gmtOffset < 0 ? '+' : '-'} ${Math.abs(gmtOffset / 60).toString().padStart(2, '0')}:00`;

        this.linesArr = this.tableContent.children;
        this.lines = this.tableContent.children.reduce((obj, children) => {
            obj[children.name.slice(5)] = children;
            return obj;
        }, {});

        this.logo.on('pointerdown', () => this.service.send('RETURN'));

        for (const gameType in this.lines) {
            const line = this.lines[gameType];

            line.icon.on('pointerdown', () => this.service.send('GET_GAME_DATA', { gameType }));
            line.icon.interactive = true;
            line.icon.buttonMode = true;
        }
    }

    createLinksForViewObjects() {
        this.map({
            time: 'time_text',
            date: 'date_text',
            tableContent: 'result_table_content',
            logo: 'logo',
            gameIcon: 'header_game_icon',
            dateHeader: 'date_header_container',
            timeHeader: 'time_header_container',
            lotteryHeader: 'lottery_header_container'
        })
    }

    showGameIcon(_, evt) {
        this.logo.x = -280;

        this.gameIcon.visible = true;
        this.gameIcon.texture = this.resources[`icon_game_${evt.gameType}`];

        this.lotteryHeader.visible = false;
        this.dateHeader.x = -158;
        this.timeHeader.x = -81;

        this.linesArr.forEach(line => line.showIcons(false));
    }

    hideGameIcon() {
        this.logo.x = 0;
        this.logo.interactive = this.logo.buttonMode = false;

        this.gameIcon.visible = false;

        this.lotteryHeader.visible = true;
        this.dateHeader.x = -175;
        this.timeHeader.x = -125;

        this.linesArr.forEach(line => line.showIcons(true));
    }

    listenForUpdates() {
        kenoTransport.on('lotteries_slot_updated', this._slotDrawUpdateListener, this);
        kenoTransport.on('lottery_combination_received', this._lotteryDrawUpdateListener, this);
        penaltyTransport.on('roulette_state_changed', this._rouletteDrawUpdateListener, this);
    }

    _lotteryDrawUpdateListener(payload) {
        const formattedData = { id: payload.draw, time: payload.time, combination: payload.combination.reverse() };

        if (!this._currentGameType) {
            this._insertData(this.lines[payload.type], formattedData);
            return;
        }

        if (this._currentGameType === payload.type)
            this._addDataToHistory(formattedData);
    }

    _slotDrawUpdateListener(payload) {
        this.updateTime(payload[0])

        if (!this._currentGameType) {
            payload.forEach(data => {
                this._insertData(this.lines[data.type], { id: data.draw, time: data.time, combination: this._getSlotCombWithScatters(data) });
            });

            return;
        }

        if (this.gameTypes.slot.includes(this._currentGameType)) {
            const currGameData = payload.find(data => data.type === this._currentGameType);
            const formattedData = { id: currGameData.draw, time: currGameData.time, combination: this._getSlotCombWithScatters(currGameData) };

            this._addDataToHistory(formattedData);
        }
    }

    _rouletteDrawUpdateListener(payload) {
        if (payload.state !== 'forbid' || payload.roulette_id !== 6)
            return;

        const formattedData = { id: payload.draw, time: payload.time, combination: [payload.number] }

        if (!this._currentGameType) {
            this._insertData(this.lines['penalty'], formattedData);
            return;
        }

        if (this._currentGameType === 'penalty')
            this._addDataToHistory(formattedData);
    }

    _addDataToHistory(newData) {
        this._currentGameDraws.unshift(newData);
        this._currentGameDraws.pop();
        this._currentGameDraws.forEach((data, index) => this._insertData(this.linesArr[index], data));
    }

    _getSlotCombWithScatters(data, gameType) {
        if (!data.type)
            data.type = gameType;

        console.warn(data.type);

        if (this.scatters[data.type] === null)
            return data.combination;

        return data.combination.map(result => result === this.scatters[data.type] ? 0 : result + 1)
    }

    async _requestSlotDrawsHistory(gameType, limit = 15) {
        const payload = await kenoTransport.requestPromise('lottery_slot_list_get', { type: gameType, limit });
        const draws = payload.lotteries[0].draws;
        const formattedData = draws.map(data => ({ id: data.draw, time: data.time, combination: this._getSlotCombWithScatters(data, gameType) }));

        return formattedData;
    }

    async _requestLotteryDrawsHistory(gameType, limit = 15) {
        const payload = await kenoTransport.requestPromise('lottery_list_get', { type: gameType, limit });
        const draws = payload.lotteries[0].draws;
        const formattedData = draws.map(data => ({ id: data.draw, time: data.time, combination: data.combination.reverse() }));

        return formattedData;
    }

    async _requestLotteriesLastDraws() {
        const lotteryHistory = {};
        const promises = this.gameTypes.lottery.map((gameType) => this._requestLotteryDrawsHistory(gameType, 1).then(history => lotteryHistory[gameType] = history[0]));

        await Promise.all(promises);

        return lotteryHistory;
    }

    async _requestRouletteDrawsHistory(limit = 14) {
        const payload = await penaltyTransport.requestPromise('roulette_list_get', { type: 'penalty', limit });
        const draws = payload.roulettes[0].draws;
        const formattedData = draws.map(data => ({ id: data.roulette_draw_id, time: data.time, combination: [data.number] }));

        this.rouletteId = payload.roulettes[0].rouletteId;

        return formattedData;
    }

    async requestLastDraws() {
        let lotteryHistory, rouletteHistory;

        await Promise.all([
            this._requestLotteriesLastDraws().then(history => lotteryHistory = history),
            this._requestRouletteDrawsHistory(1).then(history => rouletteHistory = history)
        ]);

        for (const gameType in lotteryHistory)
            this._insertData(this.lines[gameType], lotteryHistory[gameType])

        this._insertData(this.lines['penalty'], rouletteHistory[0]);
    }

    removeUpdatesListener() {
        kenoTransport.removeAllListeners();
        penaltyTransport.removeAllListeners();
    }

    async listenForGameUpdates(_, evt) {
        this.tableContent.visible = false;

        let drawsHistory;

        if (this.gameTypes.slot.includes(evt.gameType))
            drawsHistory = await this._requestSlotDrawsHistory(evt.gameType);
        else if (this.gameTypes.lottery.includes(evt.gameType))
            drawsHistory = await this._requestLotteryDrawsHistory(evt.gameType);
        else if (this.gameTypes.roulette.includes(evt.gameType))
            drawsHistory = await this._requestRouletteDrawsHistory()

        this._currentGameType = evt.gameType;
        this._setGameDataHistory(drawsHistory);
        this.tableContent.visible = true;
        this.logo.interactive = this.logo.buttonMode = true;
    }

    removeGameUpdatesListener() {
        this._currentGameType = null;
        this._currentGameDraws = [];
    }

    updateTime(data) {
        this.time.text = this._getTime(data.time);
        this.date.text = `${this._getDate(data.time)} GMT ${this.gmt}`;
    }

    _setGameDataHistory(draws) {
        draws.forEach((draw, index) => this._insertData(this.linesArr[index], draw));
        this._currentGameDraws = draws;
    }

    _insertData(line, data) {
        if (!line)
            return;

        line.number.text = '#' + data.id;
        line.date.text = this._getDate(data.time);
        line.time.text = this._getTime(data.time);

        line.setCombinationLength(data.combination.length);

        data.combination.forEach((result, index) => {
            line.result[index].text = result;
        });
    }

    _getTime(utc) {
        const date = new Date(utc);

        return `${formatDate(date.getHours())}:${formatDate(date.getMinutes())}:${formatDate(date.getSeconds())}`;
    }

    _getDate(utc) {
        const date = new Date(utc);

        return `${formatDate(date.getDate())}.${formatDate(date.getMonth() + 1)}.${date.getFullYear()}`;
    }
}
