import EE from 'eventemitter3';
import SockJS from 'sockjs-client';

const noop = () => {};

export default class TransportWebClient extends EE {
    constructor(options = {}) {
        super();

        this.client = null;
        this.closed     = true;
        this.reconnect  = false;
        this.connecting = false;
        this.reconnect_time = options.reconnect_time || 1000;
        this.is_reconnect = options.is_reconnect || false;

        this.counter_key = 1;
        this.requests = {};
        this.request_timeout = options.request_timeout || 5000;

        this.is_check_ping_monitoring = options.is_check_ping_monitoring || false;

        this.check_ping_monitoring = null;
        this.check_ping_monitoring_curr_tick  = 0;
        this.check_ping_monitoring_interval   = (options.check_ping_monitoring_interval || 10) * 1000;
        this.check_ping_monitoring_count_tick = options.check_ping_monitoring_count_tick || 5;
        this.ping = 0;

        this.not_log = options.not_log;

        const opts = ['prefix', 'host', 'port', 'protocol'];

        opts.forEach(opt => {
            if (!options.hasOwnProperty(opt))
                throw new Error(`There is no ${opt} in TransportWebClient Options`);
        });

        this.url = options.protocol + '://' + options.host + ':' + options.port + '/' + options.prefix;

        if (this.is_reconnect) {
            this.connect();
        }
    }

    next_message(message) {
        let json = {};
        let data = {};

        try {
            json = JSON.parse(message);
            data = json.data || {};

            if (!json.event)
                return;
        }
        catch (exeption) {
            throw new Error('socket.raw.on("data")', message);
        }

        let not_log = false;

        if (this.not_log && this.not_log.take) {
            for (let i = 0; i < this.not_log.take.length; ++i) {
                if (json.event == this.not_log.take[i]) {
                    not_log = true;
                    break;
                }
            }
        }

        if (!not_log) {
            console.info('Take message:', JSON.stringify(json));
        }

        if (json.key) {
            if (this.requests[json.key]) {
                clearTimeout(this.requests[json.key].timeout);
                this.requests[json.key].callback(null, json.data);
                delete this.requests[json.key];
            }
        }
        else {
            this.emit(json.event, data, noop);
            this.emit('message', json, noop);
        }
    }

    take_message(data) {
        const result = data.toString().split('}{');
        if (result.length > 1) {
            for (let i = 0; i < result.length; i++) {
                let message = result[i];

                if (i !== 0)
                    message = '{' + message;

                if (i != result.length - 1)
                    message = message + '}';

                this.next_message(message);
            }
        }
        else {
            this.next_message(data.toString());
        }
    }

    request(event, data, callback, target, not_log) {
        if (typeof data === 'function') {
            callback = data;
            data = {};
        }

        if (this.closed) {
            callback({ code: 1000, message : 'Socket closed', result : { closed: true } });
        }
        else {
            const timeout = setTimeout(() => {
                delete this.requests[timeout];
                callback({ code: 1001, message : 'Request timeout', result : { timeout: true } });

            }, this.request_timeout);

            this.send(event, data, target, timeout, not_log);

            this.requests[timeout] = { callback, timeout };
            this.counter_key++;
        }
    }

    send_raw(message, not_log) {
        if (typeof message !== 'string') {
            message = JSON.stringify(message);
        }

        if (!this.closed) {
            this.send_data(message);

            if (!not_log) {
                console.info('Send message:', message);
            }
        }
        else {
            console.warn('Socket closes, message has not been sent');
        }
    };

    send(event, data, target, key, not_log = false) {
        if (this.not_log && this.not_log.take) {
            for(let i = 0; i < this.not_log.take.length; i++) {
                if (event == this.not_log.take[i]) {
                    not_log = true;
                    break;
                }
            }
        }

        data = data || {};

        this.send_raw(JSON.stringify({ event, data, target, key }), not_log);
    };

    check_ping_monitoring_start() {
        this.check_ping_monitoring = setInterval(() => {

            this.request('check_ping_monitoring', {
                time : parseInt(Date.now()),
                ping : this.ping
            },
            (error, data) => {
                if (!error) {
                    this.ping = parseInt(Date.now()) - data.result.time;
                    this.check_ping_monitoring_curr_tick--;
                    // console.info(`Check ping monitoring Tick: ${this.check_ping_monitoring_curr_tick}, Ping: ${this.ping}`);
                }
            }, undefined, true);

            this.check_ping_monitoring_curr_tick++;

            if (this.check_ping_monitoring_curr_tick > this.check_ping_monitoring_count_tick) {
                this.close();
            }

        }, this.check_ping_monitoring_interval);
    };

    check_ping_monitoring_stop() {
        if (this.check_ping_monitoring) {
            clearInterval(this.check_ping_monitoring);
            this.check_ping_monitoring_curr_tick = 0;
            this.check_ping_monitoring = null;
        }
    };

    connect() {
        if (this.closed && !this.connecting) {
            this.connecting = true;

            console.info(`Connecting to: ${this.url}`);

            this.client = new SockJS(this.url);

            this.client.onopen = evt => {
                this.closed = false;

                if (this.is_check_ping_monitoring) {
                    console.info('is_check_ping_monitoring');
                    this.check_ping_monitoring_start();
                }

                console.info('Transport client WS connected!');
                this.emit('connection');
            };

            this.client.onmessage = event => this.take_message(event.data);

            this.client.onclose = () => this.close();
        }
    }

    close() {
        if (!this.closed) {
            this.client.close();
            this.client = null;
            this.closed = true;
            this.encrypt_key = null;
            this.check_ping_monitoring_stop();

            console.info('Transport client WS closed');
            this.emit('close', {}, noop, {});
        }

        this.connecting = false;

        if (!this.reconnect && this.is_reconnect) {
            this.reconnect = true;
            setTimeout(() => {

                console.info(`Transport client WS reconnect ${this.reconnect_time} mls`);
                this.reconnect = false;
                this.connect();
            }, this.reconnect_time);
        }
    }

    send_data(message) {
        if (this.client && this.client.send) {
            this.client.send(message);
        }
    }

  requestPromise(event, data, target, notLog) {
    return new Promise((resolve, reject) => {
      this.request(event, data, (err, res) => {
        if (err) {
          console.log(`err ${event}`, err);
          reject(err);
        } else {
          if (!res.code) {
            resolve(res.result);
          } else {
            reject(res);
          }
        }
      }, target, notLog);
    });
  }
}
