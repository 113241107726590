export default {
    id: 'game_service',
    initial: 'init',
    context: {},
    states: {
        init: {
            exit: ['listenForUpdates'],
            invoke: {
                id: 'init',
                src: 'init',
                onDone: 'list'
            }
        },
        list: {
            entry: ['hideGameIcon', 'requestLastDraws'],
            exit: [],
            on: {
                GET_GAME_DATA: { target: 'game' }
            }
        },
        game: {
            entry: ['listenForGameUpdates','showGameIcon'],
            exit: ['removeGameUpdatesListener'],
            on: {
                RETURN: { target: 'list' }
            }
        },
    }
};
