import { Application, Scene } from 'core';
import GameScene from './scenes/game/GameScene';
import { kenoTransport, penaltyTransport } from './transport/transport'

export default class Game extends Application {
    constructor() {
        super({ width: 1920 / 3, height: 1080 / 3 });

        this.preloadAssets = [
            { name: 'resources', url: 'assets/resources.gen.@3x.json' }
        ];
    }

    async load() {
        const connectingSceneView = {
            type: 'sprite',
            texture: 'background',
            anchor: 0.5,
            children: [{
                type: 'text',
                text: 'CONNECTING',
                anchor: 0.5,
                scale: 0.5,
                style: { fill: 0xFFFFFF, fontSize: 50 }
            }]
        };
        const connectingScene = new Scene(connectingSceneView, this.resources);
        this.addScene('connecting', connectingScene);

        await Promise.all([
            new Promise(resolve => {
                if (kenoTransport.closed)
                    kenoTransport.on('connection', resolve);
                else
                    resolve();
            }),
            new Promise(resolve => {
                if (penaltyTransport.closed)
                    penaltyTransport.on('connection', resolve);
                else
                    resolve();
            }),
        ]);

        this.removeScene('connecting')
    }

    async startGame(context, event) {
        return new Promise((resolve, reject) => {
            const gameScene = new GameScene(this.resources);

            this.addScene('game', gameScene);

            const service = gameScene.createService({ parent: this.fsm });

            const done = () => {
                this.removeScene('game');
                resolve();
            };

            service.onDone(done).start();
        });
    }
}
