function getHeaderChildren(el) {
    const children = [{
        type: 'text',
        name: el.name,
        text: el.text,
        anchor: { x: 0, y: 0.5 },
        style: {
            fontSize: 14.5,
            fill: 0x969696
        },
        scale: 0.5
    }];

    if (!el.icon)
        return children;

    children.push({
        type: 'sprite',
        name: el.name + '_icon',
        anchor: { x: 1, y: 0.5 },
        texture: el.icon,
        x: -3,
    });

    return children;
}

const games = [
    'durdonai_garonbaho',
    'gambusaki_hugihol',
    'haradmandi_hushkhol',
    'hocha_nastriddin',
    'kissaho',
    'kissahoj_pomir',
    'mevaho',
    'misa',
    'rohi_abreshim',
    'turbo_keno',
    'x_keno',
    'z_keno',
    'jx_keno',
    'keno_22',
    'penalty',
    'lotosport',
];

const getGameLine = i => ({
    type: 'sprite',
    texture: 'line',
    name: 'line_' + games[i],
    y: i * 21.6,
    anchor: 0.5,
    children: [
        {
            type: 'text',
            name: `line_${games[i]}_number`,
            text: '#35.01.0000000056900063',
            anchor: 0.5,
            scale: 0.5,
            x: -257,
            style: contentStyle
        },
        {
            type: 'text',
            name: `line_${games[i]}_date`,
            text: '22.12.2021',
            anchor: 0.5,
            scale: 0.5,
            x: -177,
            style: contentStyle
        },
        {
            type: 'text',
            name: `line_${games[i]}_time`,
            text: '11:44:55',
            anchor: 0.5,
            scale: 0.5,
            x: -120,
            style: contentStyle
        },
        {
            type: 'sprite',
            texture: `icon_game_${games[i]}`,
            name: `line_${games[i]}_icon`,
            anchor: 0.5,
            // scale: 0.28,
            x: -57
        },
        ...Array.from({ length: 22 }, (_, j) => ({
            type: 'text',
            text: '11',
            x: -5 + (340.5 / 22 - 1) * j,
            name: `line_${games[i]}_result_${j}`,
            anchor: 0.5,
            scale: 0.5,
            style: {
                fontWeight: 'bold',
                fontSize: 16,
                fill: 0xFBEB4E
            }
        }))
    ],
    init: line => {
        if (!games[i])
            line.visible = false;

        line.number = line.getChildByName(`line_${games[i]}_number`);
        line.date = line.getChildByName(`line_${games[i]}_date`);
        line.time = line.getChildByName(`line_${games[i]}_time`);
        line.icon = line.getChildByName(`line_${games[i]}_icon`);
        line.result = [];

        for (let j = 0; j < 22; j++)
            line.result.push(line.getChildByName(`line_${games[i]}_result_${j}`, true));

        line.setCombinationLength = length => {
            line.result.forEach((el, j) => {
                el.x = -5 + (340.5 / length - 1) * j;
                el.visible = j < length;
            });

            if (length === 1)
                line.result[0].x = 147 ;
        }

        line.showIcons = (show = true) => {
            line.icon.visible = show;
            line.date.x = show ? -174 : -154;
            line.time.x = show ? -120 : -75;
        }
    }
});

const headers = [
    {
        icon: 'icon_number',
        text: 'Номер тиража',
        name: 'number_header',
        x: -276
    },
    {
        icon: 'icon_date',
        text: 'Дата',
        name: 'date_header',
        x: -175
    },
    {
        icon: 'icon_time',
        text: 'Время',
        name: 'time_header',
        x: -125
    },
    {
        icon: 'icon_ticket',
        text: 'Лотереи',
        name: 'lottery_header',
        x: -66
    },
    {
        icon: 'icon_list',
        text: 'Список тиражей электронной лотереи',
        name: 'list_header',
        x: 87
    },
].map(el => ({
    type: 'container',
    name: el.name + '_container',
    x: el.x || 0,
    children: getHeaderChildren(el)
}));
const contentStyle = {
    fontSize: 14,
    fill: 0xFFFFFF
}

const lines = Array.from(games, (_, i) => getGameLine(i));

export default {
    type: 'container',
    name: 'game_scene',
    children: [
        {
            type: 'sprite',
            name: 'background',
            texture: 'background',
            anchor: 0.5
        },
        {
            type: 'sprite',
            name: 'logo',
            texture: 'logo',
            anchor: 0.5,
            y: -165
        },
        {
            type: 'sprite',
            name: 'header_game_icon',
            texture: 'icon_game_mevaho',
            anchor: 0.5,
            // scale: 0.4,
            visible: false,
            y: -165
        },
        {
            type: 'container',
            name: 'date_container',
            x: 286,
            y: -169,
            children: [
                {
                    type: 'text',
                    name: 'time_text',
                    text: '21:29:02',
                    anchor: 0.5,
                    scale: 0.5,
                    style: {
                        fill: 0xFFFFFF,
                        fontSize: 23
                    }
                },
                {
                    type: 'text',
                    name: 'date_text',
                    text: '21.12.2021 GMT + 05:00',
                    anchor: 0.5,
                    scale: 0.5,
                    x: -2,
                    y: 11,
                    style: {
                        fill: 0xFFFFFF,
                        fontSize: 10
                    }
                }
            ]
        },
        {
            type: 'container',
            name: 'result_table',
            y: -122,
            scale: 0.9,
            children: [
                {
                    type: 'sprite',
                    name: 'result_table_header',
                    texture: 'line',
                    y: -22,
                    anchor: 0.5,
                    children: headers
                },
                {
                    type: 'container',
                    name: 'result_table_content',
                    children: lines
                }
            ]
        },
        {
            type: 'sprite',
            name: 'blueprint',
            texture: 'blueprint',
            anchor: 0.5,
            alpha: 0
        }
    ],
};
